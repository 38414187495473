import { CountryLanguageUrls } from '../providers/settings.service';
import { AnySection } from './section';

export enum PageType {
  Website = 'website',
  Article = 'article',
}
export interface LocalLink {
  url: string;
  label: string;
}

interface PageBase {
  type: PageType;
  path: string;
  canonical: string;
  title: string;
  headline?: string;
  description: string;
  modifiedDate: string;
  currentLanguage: string;
  sections: AnySection[];
  excludeFromSearch: boolean;
  disableInitialCookieDialog: boolean;
}

export type Person = {
  name: string;
  jobTitle?: string;
  honorificPrefix?: string;
  url?: string;
}

export type WebsitePage = PageBase & { type: PageType.Website };
export type ArticlePage = PageBase & {
  type: PageType.Article;
  publishedDate: string;
  authors?: Person[];
};

export type PageCommon = WebsitePage | ArticlePage;

export type PageWithCountries = PageCommon & {
  currentCountry: string;
  countryLanguageCombinations: CountryLanguageUrls;
  localContentLink: LocalLink;
  isLocalContentPage: boolean;
}

export type PreviewPageCommon = {
  preview: true;
  globalSettingsUrl: string;
  newSectionButton: string;
  editorIds: { [formFieldName: string]: string };
}

export type PreviewPageWithCountries = PreviewPageCommon & PageWithCountries;

export function isPageWithCountries(page: Page): page is PageWithCountries | PreviewPageWithCountries {
  return (page as PageWithCountries).countryLanguageCombinations !== undefined;
}

export type LanguageUrls = { [language: string]: string };

export type PageWithoutCountries = PageCommon & {
  languages: LanguageUrls;
}

export type PreviewPageWithoutCountries = PreviewPageCommon & PageWithoutCountries;

export function isPageWithoutCountries(page: Page): page is PageWithoutCountries | PreviewPageWithoutCountries {
  return (page as PageWithoutCountries).languages !== undefined;
}

export type PreviewPage = PreviewPageWithCountries | PreviewPageWithoutCountries;

export function isPreviewPage(page: Page): page is PreviewPage {
  return (page as PreviewPage).preview;
}

export type Page = PageWithCountries | PageWithoutCountries | PreviewPage;
